import React, { useState, useEffect, useCallback } from "react";
import { Button, Card } from "react-bootstrap";
import ModalError from "../../modals/ModalError";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import fetcherGet from "../../fetcher/fetcherGet";
import ModalBorovnicaManjkajociPodatki from "../../modals/ModalBorovnicaManjkajociPodatki";

const BorovnicaSplosniPodatki = ({ setJePrikazSplosnihPodatkov }) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId, currGerk } = useCommonContext();
  const [borovnicaSplosniPodatki, setBorovnicaSplosniPodatki] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const fetchSplosniPodatkiBorovnica = useCallback(
    (signal) => {
      return fetcherGet(
        "borovnica/gerkData",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setBorovnicaSplosniPodatki
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchSplosniPodatkiBorovnica(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Title>
          <h3 className="text-center mb-4">
            Splošni podatki o nasadu ameriške borovnice: {currGerk}
          </h3>
        </Card.Title>
        <Card.Body>
          <p>
            <b>Ime in priimek nosilca: </b> {borovnicaSplosniPodatki.imeNosilca}{" "}
            {borovnicaSplosniPodatki.priimekNosilca}
          </p>

          <p>
            <b>Število sadik na površino: </b>
            {borovnicaSplosniPodatki.steviloSadikNaPovrsino}
          </p>
          <p>
            <b>Število sadik na hekar: </b>
            {borovnicaSplosniPodatki.steviloSadikNaHektar}
          </p>
          <p>
            <b>Število sadik na tekoči meter: </b>
            {borovnicaSplosniPodatki.steviloSadikNaTekociMeter}
          </p>
          <p>
            <b>Razdalja med vrstami: </b>
            {borovnicaSplosniPodatki.razdaljaMedVrstami}m
          </p>
          <p>
            <b>Sorta: </b>
            {borovnicaSplosniPodatki.sorta}
          </p>
          <p>
            <b>Kvaliteta sadik: </b>
            {borovnicaSplosniPodatki.kvalitetaSadik}
          </p>
          <p>
            <b>Leto nasaditve: </b>
            {borovnicaSplosniPodatki.letoNasaditve}
          </p>
          {/* <p>
            <b>Čas sajenja: </b>
            {
              new Date(borovnicaSplosniPodatki.casSajenja)
                .toLocaleString("sl-Sl")
                .split(",")[0]
            }
          </p> */}
          <p>
            <b>Rodnost: </b>
            {borovnicaSplosniPodatki.jeEnkratRodna === true
              ? "Enkrat rodne"
              : "Dvakrat rodne"}
          </p>
          <p className="text-center">
            <Button
              onClick={() => {
                setJePrikazSplosnihPodatkov(false);
              }}
            >
              Uredi podatke
            </Button>
          </p>
        </Card.Body>
      </Card>
      {Object.keys(borovnicaSplosniPodatki).length > 0 && (
        <ModalBorovnicaManjkajociPodatki
          seModalnoPrikaze={
            borovnicaSplosniPodatki.kvalitetaSadik ===
            "Kvaliteta sadik ni navedena"
          }
          izbranGERK={currGerk}
          nasadId={nasadId}
          setModalError={setModalError}
          setShowModal={setShowModal}
          signOut={signOut}
          history={history}
          fetchSplosniPodatkiBorovnica={fetchSplosniPodatkiBorovnica}
        />
      )}

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default BorovnicaSplosniPodatki;
