import React, { useState,useEffect,useCallback } from 'react';
import { Modal, Button, Table, Form, Alert } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import fetcherGet from "../fetcher/fetcherGet";
import BASE_URL from '../../utils/baseUrl';



const NadzornaPlosca = (props) => {

  const [showConfirmModal, setShowConfirmModal] = useState(false); //modal za potrditev spremembe gesla
  const [successResetPassword, setSuccessResetPassword] = useState(false);

  //alerti v modalnem oknu posameznega uporabnika
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState(''); // success or danger
  const [showAlert, setShowAlert] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);
  const [showModalUser, setShowModalUser] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // State for the search term
  const [users, setUsers] = useState([]);


  const signOut = useSignOut();
  const history = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [imagesUrls, setImagesUrls] = useState([]);



  const fetchUporabniki = useCallback(
    (signal) => {
      return fetcherGet(
        "uporabnik/uporabniki",
        signal,
        null,
        setModalError,
        setShowModal,
        signOut,
        history,
        (users) => {
          console.log("Uporabniki: ",users.uporabniki);
          setUsers(users.uporabniki)
        }
      );
    },
    [ history, signOut]
    
  )

  const fetchImmages = async () => {
    try {
      const response = await fetch(BASE_URL+`/slikeData/nadzornaPlosca`, {
        method: 'POST',
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          midStevilka: selectedUser.midStevilka, // Pošlji MID številko v telo zahteve
        }),
      });
  
      const data = await response.json();

      // const response1 = await fetch(`${BASE_URL}/slikeData/getZipImages`, {
      //   method: 'POST',
      //   credentials: 'include', // Poskrbi za piškotke
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ data }), // Pošlji array slik na backend
      // });
      // const data1 = await response1.json();
      // console.log("dirname:", data1);
  
      if (response.ok && data.length > 0) {
        // downloadImagesAsZip([
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        //   { gerk: 2569957, id:439,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713167964829_33.jpg" },
        //   { gerk: 2569957, id:440,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006625_33.jpg" },
        //   { gerk: 2569957, id:441,jablanaId: 33, linkSlike: "https://digisad.feri.um.si/slike/1713168006670_33.jpg" },
        //   { gerk: 772254, id:1450,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796340_1303.jpg" },
        //   { gerk: 772254, id:1451,jablanaId: 1303, linkSlike: "https://digisad.feri.um.si/slike/1721058796390_1303.jpg" },
        // ]);
         downloadImagesAsZip(data);
      } else {
        setShowAlert(true);
        setAlertVariant('danger');
        setAlertMessage('Napaka pri pridobivanju podatkov: ' +  'Uporabnik nima naloženih slik');
        console.error('Napaka pri pridobivanju podatkov:', data.message || 'Uporabnik nima naloženih slik');
      }
    } catch (error) {
      console.error('Napaka pri izvajanju zahteve:', error.message);
    }
  };




  // const downloadImagesAsZip = async (images) => {//VERZIJA KJER SE ENA PO ENA SLIKA POSLJE NA FRONTEND KJER SE ZAPAKIRAJO V ZIP
  //   try {
  //     const zip = new JSZip();
  
  //     for (const image of images) {
  //       const { gerk, linkSlike } = image;
  
  //       if (!gerk || !linkSlike) continue;
  
  //       // Fetch sliko iz backend API
  //       const response = await fetch(`${BASE_URL}/slikeData/prenesiSliko`, {
  //         method: 'POST',
  //         credentials: 'include',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({ imageUrl: linkSlike }),
  //       });
  
  //       if (response.ok) {
  //         const blob = await response.blob();
  //         const fileName = linkSlike.split('/').pop(); // Ustvari ime datoteke iz URL
  
  //         // Dodaj sliko v mapo glede na gerk
  //         const folder = zip.folder(gerk.toString());
  //         folder.file(fileName, blob);
  //       } else {
  //         console.error(`Napaka pri prenosu slike: ${linkSlike} - ${response.statusText}`);
  //       }
  //     }
  
  //     // Generiraj ZIP datoteko
  //     const zipBlob = await zip.generateAsync({ type: 'blob' });
  
  //     // Shrani ZIP datoteko
  //     const downloadLink = document.createElement('a');
  //     downloadLink.href = URL.createObjectURL(zipBlob);
  //     downloadLink.download = 'images.zip';
  //     downloadLink.style.display = 'none';
  //     document.body.appendChild(downloadLink);
  //     downloadLink.click();
  
  //     // Po prenosu očistimo URL in odstranimo element
  //     URL.revokeObjectURL(downloadLink.href);
  //     document.body.removeChild(downloadLink);
  
  //     console.log('ZIP datoteka uspešno ustvarjena in prenesena');
  //   } catch (error) {
  //     console.error('Napaka pri ustvarjanju ZIP datoteke:', error.message);
  //   }
  // };

  const downloadImagesAsZip = async (images) => {//prek spleta dostopam do vseh slik zato je prenos zelo zamuden
    try {
      const response = await fetch(`${BASE_URL}/slikeData/getZipImages`, {
        method: 'POST',
        credentials: 'include', // Poskrbi za piškotke
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ images }), // Pošlji array slik na backend
      });
  
      if (response.ok) {
        setShowAlert(true);
      setAlertVariant('success');
      setAlertMessage('Prenos v teku, prosim ne zapirajte modalnega okna do konca prenosa...');
        const blob = await response.blob();
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `${selectedUser.ime}_${selectedUser.priimek}_${selectedUser.uporabniskoIme}_slike.zip`;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
  
        // Po prenosu očistimo URL in odstranimo element
        URL.revokeObjectURL(downloadLink.href);
        document.body.removeChild(downloadLink);
  
        console.log('ZIP datoteka uspešno prenesena');
      } else {
        setShowAlert(true);
      setAlertVariant('danger');
      setAlertMessage('Napaka pri prenosu ZIP datoteke: ' + response.statusText);
        console.error('Napaka pri prenosu ZIP datoteke:', response.statusText);
      }
    } catch (error) {
      console.error('Napaka pri povezavi z backendom:', error.message);
    }
  };
  
  




  useEffect(() => {
    const controller = new AbortController();
    fetchUporabniki(controller.signal);
    // fetchUporabniki();
  }, []);

  // Funkcija, ki odpre modalno okno z izbranim uporabnikom
  const handleShowModal = (user) => {
    setSelectedUser(user);
    setShowModalUser(true);
  };

  // Funkcija za zapiranje modala
  const handleCloseModal = () => {
    setShowModalUser(false);
    setSuccessResetPassword(false);
    setSelectedUser(null);
    setShowAlert(false);
  };

  const handlePasswordReset = async () => {

console.log("izbrani uporabnik: ",selectedUser)
    try {
      const response = await fetch(BASE_URL+`/uporabnik/ponastaviGeslo`, {
        method: 'PUT',
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: selectedUser?.id,
          uporabniskoIme: selectedUser?.uporabniskoIme
        }),
      });


      const data = await response.json();

      if (response.status === 200) {
        console.log(data.message || 'Geslo uspešno ponastavljeno!');  
        setSuccessResetPassword(true);
      } else {
        console.log(data.message || 'Napaka pri ponastavitvi gesla.'); 
      }
    } catch (error) {
      console.log(`Napaka pri ponastavitvi gesla: ${error.message}`);
    }
    handleCloseConfirmModal();
  };

  const filteredUsers = users.filter((user) =>
    Object.values(user).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  //modal za potrditev spremembe gesla
  const handleOpenConfirmModal = (user) => {
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => setShowConfirmModal(false);

  return (
    <div className="container mt-5">
      <h2>Seznam uporabnikov</h2>

      
      <Form.Control
        type="text"
        placeholder="Filtriraj uporabnike..."
        className="mb-3"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#Id</th>
            <th>Ime</th>
            <th>Priimek</th>
            <th>Uporabniško ime</th>

            <th>Akcija</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.ime}</td>
              <td>{user.priimek}</td>

              <td>{user.uporabniskoIme}</td>
              <td>
                <Button variant="primary" onClick={() => handleShowModal(user)}>
                  Možnosti
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modalno okno */}
      <Modal show={showModalUser} onHide={handleCloseModal} 
  style={{ filter: showConfirmModal ? "brightness(0.6)" : "brightness(1)" }}>
        <Modal.Header closeButton>
          <Modal.Title>Akcije za uporabnika: {selectedUser?.uporabniskoIme}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p><strong>Ime:</strong> {selectedUser?.ime}</p>
            <p><strong>Priimek:</strong> {selectedUser?.priimek}</p>
            <p><strong>MID Številka:</strong> {selectedUser?.midStevilka}</p>
            <p><strong>Ulica:</strong> {selectedUser?.ulica}</p>
            <p><strong>Hišna številka:</strong> {selectedUser?.hisnaStevilka}</p>
            <p><strong>Poštna številka:</strong> {selectedUser?.postnaStevilka}</p>
            <p><strong>Kraj:</strong> {selectedUser?.kraj}</p>
          <p>Izberi akcijo za tega uporabnika:</p>
          <Button variant="warning"  onClick={handleOpenConfirmModal} className="me-2">Spremeni geslo</Button>
          <Button variant="success" onClick={fetchImmages} >Prenesi fotografije</Button>
           {/* Alert for success message */}
        <Alert
          key={"success"}
          variant={"success"}
          show={successResetPassword}
          className="m-2"
        >
          Uspešno ponastavljeno geslo!
        </Alert>
        <Alert
          variant={alertVariant}
          onClose={() => setShowAlert(false)}
          dismissible
          className="m-2"
          show={showAlert}
        >
          {alertMessage}
        </Alert>
        
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="secondary" onClick={handleCloseModal}>
            Zapri
          </Button>
          
          
        </Modal.Footer>
      </Modal>

            {/* Modal za potrditev akcije */}
            <Modal centered show={showConfirmModal} onHide={handleCloseConfirmModal} backdrop={showConfirmModal ? 'static' : true}>
        <Modal.Header closeButton>
          <Modal.Title>Potrditev ponastavitve gesla</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Ali ste prepričani, da želite ponastaviti geslo za tega uporabnika?
          Novo geslo: geslo123
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmModal}>
            Prekliči
          </Button>
          <Button variant="warning" onClick={handlePasswordReset}>
            Potrdi
          </Button>
        </Modal.Footer>
      </Modal>
      
  
    </div>
  );
}

export default NadzornaPlosca;
