import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import fetcherPost from "../../fetcher/fetcherPost";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";

const BorovnicaSteviloPoganjkov = ({
  vsiStPoganjkov,
  fetchPodatkeUkrepi,
  setShowModal,
  setModalError,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [datumStPoganjkov, setDatumStPoganjkov] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const OB_KONCU_VEGETACIJE = "ob koncu vegetacije";
  const PO_REZI = "po rezi";

  const [stPoganjkov, setStPoganjkov] = useState("");
  const [kdajJeMeritev, setKdajJeMeritev] = useState(OB_KONCU_VEGETACIJE);

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(vsiStPoganjkov?.length > 0 ? false : true);
  }, [vsiStPoganjkov]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "DarkKhaki" }}>
        <Card.Header>
          Podatki o številu poganjkov pred rezjo in po rezi
        </Card.Header>

        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "DarkKhaki" }}>Datum</th>
                  <th style={{ backgroundColor: "DarkKhaki" }}>
                    Število poganjkov
                  </th>
                  <th style={{ backgroundColor: "DarkKhaki" }}>
                    Kdaj je bila meritev opravljena?
                  </th>

                  {vsiStPoganjkov[0]?.datum &&
                  new Date(vsiStPoganjkov[0]?.datum).getFullYear() ===
                    new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "DarkKhaki" }}>Izbris </th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {vsiStPoganjkov &&
                  vsiStPoganjkov.map((podatek) => (
                    <DeletionRow
                      id={podatek.id}
                      key={podatek.id}
                      link={"borovnica/ukrep"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchPodatkeUkrepi}
                      briseSe={"ST_POGANJKOV"}
                      jeTrenutnoLeto={
                        new Date(podatek.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>
                            <EditableCell
                              initialValue={podatek.datum}
                              inputType={"date"}
                              id={podatek.id}
                              link={"borovnica/ukrep"}
                              imeLastnosti={"datum"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeUkrepi}
                              spreminjaSe={"ST_POGANJKOV_DATUM"}
                              jeTrenutnoLeto={
                                new Date(podatek.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={podatek.stPoganjkov}
                              inputType={"number"}
                              id={podatek.id}
                              link={"borovnica/ukrep"}
                              imeLastnosti={"stPoganjkov"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeUkrepi}
                              spreminjaSe={"ST_POGANJKOV_STEVILO"}
                              step={1}
                              min={0}
                              max={Number.MAX_SAFE_INTEGER}
                              jeTrenutnoLeto={
                                new Date(podatek.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={
                                podatek.jeObKoncuVegetacije
                                  ? OB_KONCU_VEGETACIJE
                                  : PO_REZI
                              }
                              inputType={"radio"}
                              radioValue={podatek.jeObKoncuVegetacije}
                              id={podatek.id}
                              link={"borovnica/ukrep"}
                              imeLastnosti={"kdajJeRez"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeUkrepi}
                              spreminjaSe={
                                "ST_POGANJKOV_JE_OB_KONCU_VEGETACIJE"
                              }
                              moznosti={[OB_KONCU_VEGETACIJE, PO_REZI]}
                              jeTrenutnoLeto={
                                new Date(podatek.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>

          <Card className="m-1" style={{ backgroundColor: "DarkKhaki" }}>
            <Card.Body>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  await fetcherPost(
                    `borovnica/ukrep`,
                    {
                      datum: datumStPoganjkov,
                      stPoganjkov: stPoganjkov,
                      jeObKoncuVegetacije:
                        kdajJeMeritev === OB_KONCU_VEGETACIJE,
                      tip: "ST_POGANJKOV",
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setStPoganjkov("");
                  setDatumStPoganjkov(new Date().toISOString().slice(0, 10));

                  fetchPodatkeUkrepi(new AbortController().signal);
                }}
              >
                <FormGroup className="m-2">
                  <Form.Label htmlFor="datumStPoganjkov">Datum:</Form.Label>
                  <Form.Control
                    id="datumStPoganjkov"
                    type="date"
                    value={datumStPoganjkov}
                    min={`${new Date().getFullYear()}-01-01`}
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={(e) => setDatumStPoganjkov(e.target.value)}
                    placeholder="Vnesite datum meritve števila poganjkov"
                  />
                  <Form.Text className="text-muted">
                    Izberite datum meritve števila poganjkov
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="stPoganjkov">
                    Število poganjkov {kdajJeMeritev}:
                  </Form.Label>
                  <Form.Control
                    id="stPoganjkov"
                    type="number"
                    min={0}
                    step={1}
                    required
                    value={stPoganjkov}
                    onChange={(e) => setStPoganjkov(e.target.value)}
                    placeholder={`Vnesite število poganjkov ${kdajJeMeritev}`}
                  />

                  <Form.Text className="text-muted">
                    Vnesite število poganjkov {kdajJeMeritev}
                  </Form.Text>

                  <div className="mb-3">
                    <Form.Check
                      inline
                      label={OB_KONCU_VEGETACIJE}
                      name={OB_KONCU_VEGETACIJE}
                      value={OB_KONCU_VEGETACIJE}
                      checked={kdajJeMeritev === OB_KONCU_VEGETACIJE}
                      type={"radio"}
                      onChange={(e) => setKdajJeMeritev(e.target.value)}
                    />
                    <Form.Check
                      inline
                      label={PO_REZI}
                      name={PO_REZI}
                      value={PO_REZI}
                      checked={kdajJeMeritev === PO_REZI}
                      type={"radio"}
                      onChange={(e) => setKdajJeMeritev(e.target.value)}
                    />
                  </div>
                </FormGroup>

                <FormGroup className="m-2">
                  <Container>
                    <Row className="g-3">
                      <Col className="d-flex align-items-center justify-content-center">
                        <Button type="submit" variant="success">
                          <span className="btn-label">
                            <i className="bi bi-plus-circle m-1"></i>
                            Shrani podatke
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default BorovnicaSteviloPoganjkov;
