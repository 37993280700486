import React, { useState, useCallback } from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import BorovnicaSplosniPodatki from "./splosniPodatki/BorovnicaSplosniPodatki";
import BorovnicaUrediSplosnePodatke from "./splosniPodatki/BorovnicaUrediSplosnePodatke";
import BorovnicaUkrepi from "./ukrepi/BorovnicaUkrepi";
import BorovnicaPridelekGlavna from "./pridelek/BorovnicaPridelekGlavna";
import BorovnicaTla from "./tla/BorovnicaTla";
import IzbiraLetaInNaslov from "../IzbiraLetaInNaslov";
import PrikazGpsPodatkovDreves from "../nastavitve/PrikazGpsPodatkovDreves";
import BorovnicaFenofaza from "./fenofaze/BorovnicaFenofaza";

const BorovnicaObrazci = () => {
  const [key, setKey] = useState("gerk");
  const [jePrikazSplosnihPodatkov, setJePrikazSplosnihPodatkov] =
    useState(true);
  const [izbranoLeto, setIzbranoLeto] = useState(new Date().getFullYear());
  const [niPodatkovZaIzbranoLeto, setNiPodatkovZaIzbranoLeto] = useState(true);

  const memoizedSetNiPodatkovZaIzbranoLeto = useCallback(
    (newValue) => setNiPodatkovZaIzbranoLeto(newValue),
    [] // Empty dependency array to prevent unnecessary re-creations
  );

  return (
    <div
      className="mt-5 d-flex justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <div className="w-100 g-3" style={{ maxWidth: "1500px" }}>
        <Card>
          <Card.Body>
            <IzbiraLetaInNaslov
              naslov={"CRP obrazec za ameriško borovnico"}
              izbranoLeto={izbranoLeto}
              setIzbranoLeto={setIzbranoLeto}
              niPodatkovZaIzbranoLeto={niPodatkovZaIzbranoLeto}
            />

            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="gerk" title="GERK">
                {jePrikazSplosnihPodatkov ? (
                  <BorovnicaSplosniPodatki
                    setJePrikazSplosnihPodatkov={setJePrikazSplosnihPodatkov}
                  />
                ) : (
                  <BorovnicaUrediSplosnePodatke
                    setJePrikazSplosnihPodatkov={setJePrikazSplosnihPodatkov}
                  />
                )}
              </Tab>

              <Tab eventKey="ukrep" title="Ukrepi">
                <BorovnicaUkrepi
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={memoizedSetNiPodatkovZaIzbranoLeto}
                />
              </Tab>
              <Tab eventKey="fenofaze" title="Fenofaze">
                <BorovnicaFenofaza></BorovnicaFenofaza>
              </Tab>
              <Tab eventKey="pridelek" title="Pridelek">
                <BorovnicaPridelekGlavna
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={memoizedSetNiPodatkovZaIzbranoLeto}
                />
              </Tab>
              <Tab eventKey="tla" title="Tla">
                <BorovnicaTla />
              </Tab>
              <Tab
            eventKey="prikazGpsPodatkovDreves"
            title="Dodatne informacije o nasadu"
          >
            <PrikazGpsPodatkovDreves disableUploading={true}
            />
          </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default BorovnicaObrazci;
