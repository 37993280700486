import React, { useCallback, useEffect, useState } from "react";
import { Card, Table, Form, FormGroup, Button, Container, Row, Col,Image } from "react-bootstrap";
import ModalError from "../../modals/ModalError";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import fetcherGet from "../../fetcher/fetcherGet";
import EditableCell from "../../EditableCell";
import BASE_URL from "../../../utils/baseUrl";
import { Trash } from "react-bootstrap-icons";



const BorovnicaFenofaza = () => {
  const signOut = useSignOut();
  const history = useNavigate();
  const { nasadId } = useCommonContext();


  const [datumFenofaze, setDatumFenofaze] = useState(new Date().toISOString().slice(0, 10));
  const [fenofaza, setFenofaza] = useState("");
  const [fenofazeList, setFenofazeList] = useState([]);
  const fenofazeValues = ["00","07","57","60","65","69","75","85","90","895" ]; 
  const fenofazeLegenda = [
    "00 - FAZA MIROVANJA",
    "07 – ZAČETEK BRSTENJA",
    "57 – VIDNI POSAMEZNI CVETOVI V SOCVETJU – ŠE ROZA BARVE",
    "60 – ZAČETEK CVETENJA, ODPRTI PRVI CVETOVI",
    "65 – POLNO CVETENJE",
    "69 – KONEC CVETENJA, VIDNI PLODIČI, NI VEČ VENČNIH LISTOV",
    "75 – PLODOVI DOSEGAJO 50% SVOJE VELIKOSTI",
    "85 – PRVI PLODOVI MODRO OBARVANI",
    "895 – VIŠEK OBIRANJA",
    "90 – KONEC OBIRANJA PRIČNE SE POSPEŠENA RAST POGANJKOV"
  ];

  const saveFenofaza = async () => {
    console.log(fenofaza, datumFenofaze, nasadId)
    try {
      const response = await fetch(`${BASE_URL}/borovnica/fenofaza/${nasadId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",

        body: JSON.stringify({
          datum: datumFenofaze,
          ukrep: fenofaza,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        setFenofazeList([...fenofazeList, { datum: datumFenofaze, fenofaza }]);
        setDatumFenofaze(new Date().toISOString().slice(0, 10));
        setFenofaza("");
        fetchFenofaze();
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error("Napaka pri shranjevanju fenofaze:", error);
    }
  };

  const fetchFenofaze = async () => {
    try {
      const response = await fetch(`${BASE_URL}/borovnica/fenofaza/${nasadId}`, {
        method: "GET",
        credentials: "include",
      });
      
      const data = await response.json();
      if (response.ok) {
        setFenofazeList(data);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error("Napaka pri pridobivanju fenofaz:", error);
    }
  };

  const deleteFenofaza = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/borovnica/fenofaza/${nasadId}/${id}`, {
        method: "DELETE",
        credentials: "include",
      });
      if (response.ok) {
        setFenofazeList(fenofazeList.filter((item) => item.id !== id));
      } else {
        const result = await response.json();
        console.error(result.message);
      }
    } catch (error) {
      console.error("Napaka pri brisanju fenofaze:", error);
    }
  };

  useEffect(() => {
    fetchFenofaze();
  }, []);


  return (
        <>
          <Card>
            <Card.Body>
              <Container>
                <Row>
                    <Card className="m-1" style={{ backgroundColor: "#e1cef2" }}>
                    <Card.Header>Podatki o fenofazah ameriške borovnice</Card.Header>
                    <Card.Body>
                        <div className="d-flex align-items-center justify-content-center">
                        <Table striped bordered hover responsive>
                            <thead>
                            <tr>
                                <th style={{ backgroundColor: "#e1cef2" }}>Datum</th>
                                <th style={{ backgroundColor: "#e1cef2" }}>Fenofaza</th>
                                <th style={{ backgroundColor: "#e1cef2" }}>Izbris</th>

                            </tr>
                            </thead>
                            <tbody>
                            {fenofazeList.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.datum}</td>
                                  <td>{item.vrstaFenofaze}</td>
                                  <td>
                                    <Button variant="danger" onClick={() => deleteFenofaza(item.id)}>
                                      <Trash />
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                        </Table>
                        </div>

                        <Card className="m-1" style={{ backgroundColor: "#e1cef2" }}>
                        <Card.Body>
                            <Form onSubmit={(e) => { e.preventDefault(); saveFenofaza(); }}>
                            <FormGroup className="m-2">
                                <Form.Label htmlFor="datumFenofaze">Datum:</Form.Label>
                                <Form.Control
                                id="datumFenofaze"
                                type="date"
                                value={datumFenofaze}
                                min={`${new Date().getFullYear()}-01-01`}
                                max={new Date().toISOString().slice(0, 10)}
                                onChange={(e) => setDatumFenofaze(e.target.value)}
                                placeholder="Vnesite datum fenofaze"
                                />
                                <Form.Text className="text-muted">Izberite datum fenofaze</Form.Text>
                            </FormGroup>

                            <FormGroup className="m-2">
                                <Form.Label htmlFor="fenofaza">Fenofaza:</Form.Label>
                                <Form.Control
                                    id="fenofaza"
                                    as="select"
                                    value={fenofaza}
                                    onChange={(e) => setFenofaza(e.target.value)}
                                    required
                                  >
                                    <option value="">Izberite fenofazo</option>
                                    <option value="00 - FAZA MIROVANJA">00 - FAZA MIROVANJA</option>
                                    <option value="07 – ZAČETEK BRSTENJA">07 – ZAČETEK BRSTENJA</option>
                                    <option value="57 – VIDNI POSAMEZNI CVETOVI V SOCVETJU – ŠE ROZA BARVE">57 – VIDNI POSAMEZNI CVETOVI V SOCVETJU – ŠE ROZA BARVE</option>
                                    <option value="60 – ZAČETEK CVETENJA, ODPRTI PRVI CVETOVI">60 – ZAČETEK CVETENJA, ODPRTI PRVI CVETOVI</option>
                                    <option value="65 – POLNO CVETENJE">65 – POLNO CVETENJE</option>
                                    <option value="69 – KONEC CVETENJA, VIDNI PLODIČI, NI VEČ VENČNIH LISTOV">69 – KONEC CVETENJA, VIDNI PLODIČI, NI VEČ VENČNIH LISTOV</option>
                                    <option value="75 – PLODOVI DOSEGAJO 50% SVOJE VELIKOSTI">75 – PLODOVI DOSEGAJO 50% SVOJE VELIKOSTI</option>
                                    <option value="85 – PRVI PLODOVI MODRO OBARVANI">85 – PRVI PLODOVI MODRO OBARVANI</option>
                                    <option value="895 – VIŠEK OBIRANJA">895 – VIŠEK OBIRANJA</option>
                                    <option value="90 – KONEC OBIRANJA PRIČNE SE POSPEŠENA RAST POGANJKOV">90 – KONEC OBIRANJA PRIČNE SE POSPEŠENA RAST POGANJKOV</option>

                                  </Form.Control>

                                <Form.Text className="text-muted">Vnesite fenofazo</Form.Text>
                            </FormGroup>

                            <FormGroup className="m-2">
                                <Container>
                                <Row className="g-3">
                                    <Col className="d-flex align-items-center justify-content-center">
                                    <Button type="submit" variant="success">
                                        <span className="btn-label">
                                        <i className="bi bi-plus-circle m-1"></i>
                                        Shrani podatke
                                        </span>
                                    </Button>
                                    </Col>
                                </Row>
                                </Container>
                            </FormGroup>
                            </Form>
                        </Card.Body>
                        </Card>

                        <Card className="m-1" style={{ backgroundColor: "#e1cef2" }}>
                          <Card.Header>Legenda Fenofaz</Card.Header>
                          <Card.Body>
                            <Row>
                              {fenofazeValues.map((value, index) => (
                                <Col key={index} xs={6} md={3} className="text-center mb-3">
                                  <Image src={`/fenofaza${value}.jpg`} thumbnail />
                                  <div>{fenofazeLegenda[index]}</div>
                                </Col>
                              ))}
                            </Row>
                          </Card.Body>
                        </Card>

                    </Card.Body>
                    </Card>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          </>
  );
}


export default BorovnicaFenofaza;
